import html from './timesheet-tab.html';
import lang from './timesheet-tab.lang.json';
import * as mixins from '../../../client/globals/mixins.js';
import server from '../../../client/asis-server.js';
import * as validators from '../../../client/globals/validators.js';
import * as translators from '../../../client/globals/translators.js';
import moment from 'moment';
import projectsheet_tab from './components/projectsheet-tab.js';


const regex_interval = /^[0-9]{1,2}:[0-9]{2}-[0-9]{1,2}:[0-9]{2}$/;
const regex_interval_array = /^[0-9]{1,2}:[0-9]{2}-[0-9]{1,2}:[0-9]{2}(,[0-9]{1,2}:[0-9]{2}-[0-9]{1,2}:[0-9]{2})*$/;


// date/datetime component
const timespan_input = {
    template: `
        <b-input 
            v-bind:value="value2str"
            v-on:change="onChange" 
            :state="state"
        ></b-input>
    `,  

    props: ['value', 'state', 'array'],

    methods:{
        onChange(value){
            // emtit both input and change event on change event 
            // to update the prop value only after the component looses focus
            value = value.trim();
            if (this.array) value = (value.length > 0) ? value.split(',') : [];
            this.$emit('input', value);
            this.$emit('change', value);
        },
    },

    computed:{
        value2str() {
            return Array.isArray(this.value) ? this.value.join(',') : this.value;
        }
    }
};


const randomize_work_hours = function(work_hours) {
    const rand = () => Math.floor((Math.random() * (max)));   // random number <0,max-1>
    const randsign = () => (Math.random() > 0.5) ? +1 : -1;

    var work_hours_randomized = {shift:null, breaks:[]};

    var u = Math.random(), v = Math.random();
    var shift_offset = (u*u < 0.3) ? 0 : randsign() * (5.0+5.0*(u>0.7));
    var break_offset = (v*v < 0.3) ? 0 : randsign() * (5.0+5.0*(v>0.7));

    var shift_parts = work_hours.shift.split('-');
    shift_parts[0] = moment(shift_parts[0], "HH:mm").add(shift_offset, 'm').format('HH:mm');
    shift_parts[1] = moment(shift_parts[1], "HH:mm").add(shift_offset, 'm').format('HH:mm');
    work_hours_randomized.shift = shift_parts.join('-');

    for (let i=0; i<work_hours.breaks.length; i++) {
        var break_parts = work_hours.breaks[i].split('-');
        break_parts[0] = moment(break_parts[0], "HH:mm").add(break_offset, 'm').format('HH:mm');
        break_parts[1] = moment(break_parts[1], "HH:mm").add(break_offset, 'm').format('HH:mm');
        work_hours_randomized.breaks.push(break_parts.join('-'));
    }

    return work_hours_randomized;
}


export default {
    props: {
        pid: Number,
        pv: Number,
        date: Object,
        impersonificationMode: Boolean,
    },

    template: html,

    i18n: {
        messages: lang
    },

    mixins: [
        mixins.loadDataOnCreate, 
        mixins.module_name,
        validators.vuelidate,
        //mixins.storeAccess,
        //mixins.storeGetters(['contracts']),
    ],

    components: {
        'shift-box': timespan_input,
        'break-box': timespan_input,
        'project-tab': projectsheet_tab,
    },

    data: function() {
        var $t = this.$t.bind(this);
        return {
            workflows: ['homeoffice','annualleave','sickday','worksitetrip'],
            timesheet_fields:[
                {key:'day', label:$t('day'), tdClass:'text-right px-3'},
                {key:'workload', label:$t('workload'), tdClass:'text-center px-1'},
                {key:'shift', label:$t('shift'), tdClass:'td-shift'},
                {key:'break', label:$t('breaks'), tdClass:'td-break'},
                {key:'meals', label:$t('meals'), tdClass:'text-center'},
                {key:'status', label:$t('status'), tdClass:'text-center'},
                {key:'requests', label:$t('requests'), tdClass:'text-center'},
                {key:'comment', label:$t('comment'), tdClass:'w-100'},
            ],
            loading: false,
            saving: false,
            contract: null,
            timesheet: null,

            timesheet_errors: [],
            timesheet_summary: null,
            default_work_hours: {},
            default_work_hours_randomize: false,
            homeoffice_allowed: false,
            homeoffice_requests: {},
            homeoffice_comment: '',
            calinfo: null,
            status_options: [],
            contract_duty_visible: false,
            contract_duty_value: 100,
            contract_duty_date: 1,
        };
    },

    validations: {
        timesheet: {
            records: {
                $each: {
                    shift: {
                        //format: validators.regex(regex_interval),
                    },
                    breaks: {
                        //format: validators.regex(regex_interval_array),
                    },
                    status: {},
                    homeoffice: {},
                }
            }
        },
        contract_duty_value: {
            //decimal: validators.decimal,
        },
    },

    methods: {
        load() {
            this.loading = true;
            this.timesheet_errors = [];
            this.timesheet_summary = null;
            server.request(
                this.module_name, 'timesheets-get', {pid:this.pid, pv:this.pv, year:this.date.year(), month:this.date.month()+1}, null,
                (data, extra)=>{
                    this.contract = data.contract;
                    this.timesheet = data.timesheet;
                    this.default_work_hours = extra.default_work_hours;
                    this.default_work_hours_randomize = extra.randomize;
                    this.homeoffice_allowed = extra.homeoffice_allowed;
                    this.status_options = extra.status_options;
                    this.homeoffice_requests = {...extra.homeoffice_requests};
                    this.calinfo = extra.calendar_info;
                    this.projects = extra.projects;
                    this.timesheet.locked = !extra.can_edit;
                    // update validation state of the component and notify parent component
                    this.$emit("validation", !this.$v.$invalid);                // notify parent about timesheet validation state
                    // notify parent about timesheet data change
                    this.$emit("change", {
                        can_edit: extra.can_edit,
                        print_url: extra.print_url,
                    });   
                    this.$v.timesheet.$reset();
                }
            ).then(() => {
                this.loading = false;
            });
        },

        save(dry_run, quiet) {
            // check if the form validates
            if (this.$v.$invalid) return;

            var confirm = 
            (Object.keys(Object.filter_by_value(this.homeoffice_requests, type=>type=='N')).length > 0) && (!dry_run)
                ? this.$refs.modalHomeoffice.show()
                : Promise.resolve(true);

            confirm.then(value => {
                if (!value) return;

                // reset all errors
                for (let i=0; i<this.timesheet.records.length; i++) { 
                    asis.vue.$set(this.timesheet.records[i], 'error', null);
                    asis.vue.$set(this.timesheet.records[i], '_showDetails', false);
                }

                this.timesheet_summmary = null;
                this.timesheet_errors = [];
                this.saving = true;
                Promise.all([
                    server.request(
                        this.module_name, 'timesheets-save', 
                        this.timesheet, {
                            dry_run, 
                            homeoffice_requests:this.homeoffice_requests, 
                            homeoffice_comment:this.homeoffice_comment,
                        }, 
                        (data)=>{
                            this.$v.timesheet.$reset();
                            this.timesheet_summary = data.summary;
                            if (!dry_run && !quiet) this.load();
                            // show modal dialog with the result
                            if (!quiet) this.$bvModal.msgBoxOk(
                                this.$tc(dry_run?'errors_check':'errors_save', this.timesheet_errors.length),
                                {centered: true}
                            ).catch(e=>{});
                        },
                        (errors)=>{
                            this.timesheet_errors = errors;
                            // show modal dialog with the result
                            if (!quiet) this.$bvModal.msgBoxOk(
                                this.$tc(dry_run?'errors_check':'errors_save', this.timesheet_errors.length),
                                {centered: true}
                            ).catch(e=>{});
                            // reactively set errors on the timesheet records
                            for (let e of this.timesheet_errors) { 
                                asis.vue.$set(this.timesheet.records[e.day-1], 'error', e.error);
                                asis.vue.$set(this.timesheet.records[e.day-1], '_showDetails', true);
                            }
                        }
                    ),

                    this.store_dispatch('save_project_contracts')
                ]).then(()=>{
                    this.saving = false;
                });
            });
        },

        onclick_shift(data, index) 
        {
            if (!this.default_work_hours.shift) return;

            if (data.shift == '') {
                var work_hours = this.default_work_hours_randomize ? randomize_work_hours(this.default_work_hours) : this.default_work_hours;
                data.shift  = work_hours.shift;
                data.breaks = work_hours.breaks;
            } else {
                data.shift = '';
                data.breaks = [];
            }
            // mark as dirty
            this.$v.timesheet.records.$each[index].shift.$touch();
        },

        onclick_signInOut(data, index)
        // react to sign-in-out button
        {
            if (!data.is_today || this.$v.timesheet.records.$each[index].shift.$invalid) return;

            if (data.shift.empty()) {
                // coming
                var shift = this.default_work_hours.shift.split('-');
                shift[0] = moment().subtract(5, 'm').format('HH:mm');
                data.shift = shift.join('-');
                if (data.breaks.length == 0) data.breaks = this.default_work_hours.breaks;
            } else {
                // leaving
                var shift = data.shift.split('-');
                shift[1] = moment().add(5, 'm').format('HH:mm');
                data.shift = shift.join('-');
            }

            // mark as dirty
            this.$v.timesheet.records.$each[index].shift.$touch();

            this.save(false, true);
        },

        onclick_meals(data, index) 
        // react to meal button click
        {
            if (!data.meals_voucher_eligible) {
                data.meals = '-';
                return;
            }
            
            const meals_cycle = {'-':'S', 'S':'-'};
            data.meals = meals_cycle[data.meals];
            if (data.meals=='S' && data.shift=='') this.onclick_shift(data, index);
        },

        onclick_status(data, index) 
        // react to meal status click
        {
            if (!data.status_canchange) return;
            // set next status in cycle
            var i = this.status_options.indexOf(data.status);  //i=-1 if not found
            data.status = this.status_options[i+1<this.status_options.length ? i+1 : 0];
            // mark as dirty
            this.$v.timesheet.records.$each[index].status.$touch();
        },

        onclick_homeoffice(data, index) 
        // react to meal status click
        {
            if (!data.homeoffice_allowed || !data.homeoffice_canchange) return;
            if (this.homeoffice_requests[data.day] == 'Q') return;  //cannot change running request

            // set or clear homeoffice request
            if (this.homeoffice_requests[data.day]) 
                asis.vue.$set(this.homeoffice_requests, data.day, null);
            else 
                asis.vue.$set(this.homeoffice_requests, data.day, data.homeoffice?'C':'N');

            console.log('ho-touch');
            // mark as dirty
            this.$v.timesheet.records.$each[index].homeoffice.$touch();
        },

        onclick_autofill(status) 
        // fill the timesheet automatically
        {
            if (!this.impersonificationMode || this.timesheet.locked) return;

            for (let i=0; i<this.timesheet.records.length; i++) {
                var r = this.timesheet.records[i];
                if (status == 'N') {
                    r.shift = '';
                    r.breaks = [];
                    r.meals = '-';
                    r.status = status;
                } else {
                    if ((r.shift=='') && (r.status=='-') && (!r.homeoffice) && r.is_working_day && r.on_contract) this.onclick_shift(r, i);
                }
            }
            this.save(true, true);

            /*
            ORIGINAL CODE:

            var self = this;
            var ts = this.currentTimesheet;
            var check_data;

            check_data = this.check_timesheets(false);
            if (!check_data) {
                asis.error(this.lang.checks.errors+' '+this.lang.checks.cannot_continue);
                return false;
            }
            var defaultShift = $("#asis-timesheets-settings input[name=shift]").val();
            var defaultBreak = $("#asis-timesheets-settings input[name=break]").val();

            if (check_data.asu.totalHoursAtWork >= check_data.asu.workingHoursPool) {
                return;
            }


            // pass 1
            var unfilled_days = 0;
            var day;
            for (day=1; day<=ts.calendar_info.days; day++) {
                var d = ts.date.nthDay(day);
                if (d.isWeekend() || d.isHoliday()) continue;
                var shiftHours = $('#'+self.getControlId('asu','shiftbox',d)).val();
                var status     = $('#'+self.getControlId('asu','statusbox',d)).text();
                if ((shiftHours==='') && (status=='-')) unfilled_days++;
            }
            var hoursTotal  = (check_data.asu.workingHoursPool-check_data.asu.totalHoursAtWork);
            var hoursPerDay = hoursTotal/unfilled_days;
            if (hoursPerDay<3.0) hoursPerDay = Math.min(3.0, hoursTotal);

            // pass 2
            var changes = 0;
            for (day=1; day<=ts.calendar_info.days; day++) {
                var d = ts.date.nthDay(day);
                if (d.isWeekend() || d.isHoliday()) continue;
                var whStart = Date.today().set({hour:8, minute:0});
                var hoursToSpend = Math.min(hoursPerDay, hoursTotal);
                var whEnd   = whStart.clone().add(hoursToSpend + (hoursToSpend>4?0.5:0.0)).hours();
                var shiftHours = $('#'+self.getControlId('asu','shiftbox',d)).val();
                var status     = $('#'+self.getControlId('asu','statusbox',d)).text();
                if ((shiftHours!=='') || (status!='-')) continue;
                $('#'+self.getControlId('asu','shiftbox',d)).val(whStart.toString('HH:mm')+'-'+whEnd.toString('HH:mm'));
                $('#'+self.getControlId('asu','breakbox',d)).val(hoursPerDay>4 ? '12:00-12:30' : '');
                hoursTotal -= hoursToSpend;
                changes++;
                if (hoursTotal < 1e-3) break;
            }
            check_data = this.check_timesheets(false);
            asis.info(this.lang.functions.autofill_asu_result + changes.toString());
            */
        },

        onchange_shift(data, index)
        // react to changes of shift value
        {
            // mark as dirty
            this.$v.timesheet.records.$each[index].shift.$touch();
        },

        onchange_breaks(data, index)
        // react to changes of breaks value
        {
            // mark as dirty
            this.$v.timesheet.records.$each[index].breaks.$touch();
        },

        getclass_meals(data, index) 
        {
            if (this.$v.timesheet.records.$each[index].$anyDirty) return null;
            if (data.meals_taken == 0) return null;

            if (data.meals_subsidised > 0)
                return (data.meals_taken <= data.meals_subsidised) ? 'meals-subsidised-full' : 'meals-subsidised-part'
            else
                return 'meals-subsidised-none';
        },

        getclass_homeoffice_request(data) 
        {
            if (this.homeoffice_requests[data.day] == 'Q')
                return 'homeoffice-request-running';
            else if (this.homeoffice_requests[data.day])
                return 'homeoffice-request';
            else
                return data.homeoffice ? 'homeoffice-approved' : '';
        },

        getclass_homeoffice_icon(data) 
        {
            if (!data.homeoffice_allowed) return '';
            switch (this.homeoffice_requests[data.day]) {
                case 'Q': return 'fas fa-h-square';
                case 'N': return 'fas fa-h-square';
                case 'C': return 'fas fa-minus-square';
                default:  return data.homeoffice ? 'fas fa-h-square' : (data.homeoffice_canchange ? 'far fa-square' : '');
            }
        },

        gettitle_homeoffice_icon(data) 
        {
            switch (this.homeoffice_requests[data.day]) {
                case 'Q': return this.$t('homeoffice_request_running_hint');
                case 'N': return this.$t('homeoffice_request_approve_hint');
                case 'C': return this.$t('homeoffice_request_cancel_hint');
                default:  return data.homeoffice ? this.$t('homeoffice_hint') : null;
            }
        },

        getclass_tablerow(item, type) 
        {
            if (type == 'row-details') return 'table-danger';
            if (!item || type !== 'row') return;
            if (item.error) return 'table-danger';
            if (item.is_holiday || item.is_weekend) return 'table-weekend';
            if (item.is_today) return 'table-today';
        },

    },


    watch: {
        // reload data whenever date or pid change
        'pid': function() {this.load();},
        'pv': function() {this.load();},
        'date': function() {this.load();},  

        '$v.$invalid': function() {
            this.$emit("validation", !this.$v.$invalid);
        }
    }
};

 
