import html from './mgr-approvals.html';
import lang from './mgr-approvals.lang.json';
import * as translators from '../../../client/globals/translators.js';
import * as mixins from '../../../client/globals/mixins.js';
import server from '../../../client/asis-server.js';
import moment from 'moment';



export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [
        mixins.module_name, 
        mixins.loadDataOnCreate, 
        mixins.storeGetters(['user'], 'asis/auth'), 
    ],

    data: function() {
        var $t = this.$t.bind(this);
        return {
            fields:[
                {key:'applicant', label:$t('applicant'), tdClass:'text-left'},
                {key:'date', label:$t('date'), tdClass:'min'},
                {key:'destination', label:$t('destination')},
                {key:'purpose', label:$t('purpose')},
                {key:'transport', label:$t('transport')},
                {key:'actions', label:''},
            ],
            requests: [],
            loading: false,
            approving: false,
        };
    },

    methods: {
        load() {
            this.loading = true;
            server.request(
                'timesheets-mgr', 'annualleave-request-list', 
                {}, null,
                (data, extra) => {
                    this.requests = data;
                }
            ).then(()=>{
                this.loading = false;
            });
        },

        approve() {
            this.approving = true;
            server.request(
                'timesheets-mgr', 'worksitetrip-request-approve-all'
            ).then(()=>{
                this.load();
                this.approving = false;
            });
        },

        // translators:
        date_asis2dmyyyy: translators.date_asis2dmyyyy,
    },

};
