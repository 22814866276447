const workflow_approval_alerts = {

    props: {
        status: String,
        responded: Boolean
    },

    i18n: {
        messages: {
            cz: {
                approval_finished: "Schvalování této žádanky bylo ukončeno. Konečný stav:",
                already_responded: "K této žádosti jste se již vyjádřil(a) dříve.",
                status: {
                    "Q": "probíhá schvalování",
                    "A": "schváleno",
                    "R": "zamítnuto",
                    "C": "zrušeno"
                },
            }, 
            en: {
                approval_finished: "This requisition'a approval has been ended. Final status:",
                already_responded: "You have responded to this request earlier.",
                status: {
                    "Q": "approving",
                    "A": "approved",
                    "R": "rejected",
                    "C": "canceled"
                },
            }
        }
    },

    template: `
        <div>
            <b-alert :show="status!='Q'" variant="warning" class="my-4">
                {{$t('approval_finished')}}
                <b-badge :variant="approval_status_class(status)" pill class="px-2 mx-2 font-size-normal text-uppercase font-size-normal">{{$t('status.'+status)}}</b-badge>
            </b-alert>

            <b-alert :show="responded" variant="warning" class="my-4">
                {{$t('already_responded')}}
            </b-alert>
        </div>
    `,

    methods: {
        approval_status_class(status) {
            switch (status) {
                case 'Q': return 'warning';
                case 'A': return 'success';
                case 'R': return 'danger';
                case 'C': return 'dark';
            }
        }
    },


}


export default workflow_approval_alerts;
