//----------------------------------------------------------------------------
// Informational system ASIS
//----------------------------------------------------------------------------
// globals/components.js
// global client-side Vue components
//----------------------------------------------------------------------------
// (c) Astronomický ústav AV ČR, v.v.i. (see licence file for legal info)
//----------------------------------------------------------------------------

'use strict';
// jshint esversion:6

import Vue from 'vue';
import * as translators from '../../globals/translators.js';
import moment from 'moment';
import jquery from 'jquery';

const date_picker_date_format = 'D.M.YYYY';
const date_picker_datetime_format = 'D.M.YYYY HH:mm';

//! Simple debug component.
//! The component displays the content of the `value` prop as preformated text.
//! The content is only shown in dev mode, otherwise nothing is shown.
//! Example: <asis-debug :value="data"></asis-debug>
Vue.component('asis-debug', {
    template: `
        <div v-if="dev_version">
        <hr/>
        <h3 v-if="name">Debug data for '{{name}}'</h3>
        <pre style="display:block">{{value}}</pre>
        </div>
        `,
    props: ['value', 'name'],
    data: function() {return {dev_version:asis.dev_version || asis.test_version}}
});



// date/datetime component
Vue.component('asis-date', {
    template: `
        <span style="position:relative; display:inline-block; width:12em;">
		<date-picker 
                v-model="date"
                :config="config"
                :disabled="disabled">
        </date-picker>
        </span>
    `,  // date-picker complains it must be placed inside a non-staticly positioned element

    props: {
        value: String,
        state: Boolean,
        disabled: {
            type: Boolean,
            default: false
        },
        allow_past: {
            type: Boolean,
            default: true
        },
        // lang: {
        //     type: String,
        //     default: 'cz'
        // },
    },

    data () {
        return {
            // communication with date-picker is made using the `date` property,
            // which takes its default value from `value` prop, but formats it to
            // locale (cz) date string representation
            date: this.value ? moment(this.value, 'YYYY/MM/DD').format(date_picker_date_format): null,
            config: {
                format: date_picker_date_format,
                useCurrent: false,
                locale: moment.locale(),//(this.lang=='en') ? 'en-gb' : 'cs',
                icons: {
                    time: 'far fa-clock',
                    date: 'far fa-calendar',
                    up: 'fas fa-arrow-up',
                    down: 'fas fa-arrow-down',
                    previous: 'fas fa-chevron-left',
                    next: 'fas fa-chevron-right',
                    today: 'fas fa-calendar-check',
                    clear: 'far fa-trash-alt',
                    close: 'far fa-times-circle'
                },
                minDate: this.allow_past ? false : moment().startOf('day'),
            }
        }
    },

	watch: {
		value(value) {
            // on value change (induced by the parent) set the date-picker value
            //console.log('watch-value-before', value);
            this.date = (typeof value === 'string') 
                ? moment(value, 'YYYY/MM/DD').format(date_picker_date_format)
                : value;
            //console.log('watch-value-after', this.date);
		},
		date(value) {
            // on local value change (induced by date-picker), notify the parent of the change,
            // but send back the string value
            //console.log('watch-date', value, moment(value,date_picker_date_format));
            this.$emit('input', value ? moment(value,date_picker_date_format).format('YYYY/MM/DD') : null);
            //console.log('watch-date emitted', moment(value,date_picker_date_format).format('YYYY/MM/DD'))
		},
		state() {
            //TODO:rewrite this using Vue
            if (this.state) jquery(this.$el).removeClass('is-invalid'); else jquery(this.$el).addClass('is-invalid'); 
		}
	}
});



// date/datetime component
Vue.component('asis-datetime', {
    template: `
        <span style="position:relative; display:inline-block; ; width:15em;">
		<date-picker 
                v-model="datetime"
                :config="config"
                :disabled="disabled">
        </date-picker>
        </span>
    `,  // date-picker complains it must be placed inside a non-staticly positioned element

    props: {
        value: String,
        state: Boolean,
        disabled: {
            type: Boolean,
            default: false
        },
        use_time: {
            type: Boolean,
            default: true
        },
        allow_past: {
            type: Boolean,
            default: true
        },
        // lang: {
        //     type: String,
        //     default: 'cz'
        // },
    },

    data () {
        var display_format = this.use_time ? date_picker_datetime_format : date_picker_date_format;
        return {
            // communication with date-picker is made using the `date` property,
            // which takes its default value from `value` prop, but formats it to
            // locale (cz) date string representation
            display_format: display_format,
            datetime: this.value ? moment(this.value, 'YYYY/MM/DD HH:mm:ss').format(display_format): null,
            config: {
                format: display_format,
                //useCurrent: true,
                stepping: 15,
                locale: moment.locale(),//(this.lang=='en') ? 'en-gb' : 'cs',
                icons: {
                    time: 'far fa-clock',
                    date: 'far fa-calendar',
                    up: 'fas fa-arrow-up',
                    down: 'fas fa-arrow-down',
                    previous: 'fas fa-chevron-left',
                    next: 'fas fa-chevron-right',
                    today: 'fas fa-calendar-check',
                    clear: 'far fa-trash-alt',
                    close: 'far fa-times-circle'
                },
                minDate: this.allow_past ? false : moment().startOf('day'),
            }
        }
    },

	watch: {
		value(value) {
            // on value change (induced by the parent) set the date-picker value
            //console.log('watch-value-before', value);
            //if (typeof value !== 'string') console.log('watch dt type:', typeof value, value);
            this.datetime = (typeof value === 'string') 
                ? moment(value, 'YYYY/MM/DD HH:mm:ss').format(this.display_format)
                : value;
            //console.log('watch-value-after', this.datetime);
		},
		datetime(value) {
            // on local value change (induced by date-picker), notify the parent of the change,
            // but send back the string value
            var dt = moment(value,this.display_format);
            //console.log('watch-date', value, dt);
            this.$emit('input', dt.isValid() ? dt.format('YYYY/MM/DD HH:mm:ss') : null);
            //console.log('watch-date emitted', dt.isValid() ? dt.format('YYYY/MM/DD HH:mm:ss') : null);
		},
		state() {
            //TODO:rewrite this using Vue
            if (this.state) jquery(this.$el).removeClass('is-invalid'); else jquery(this.$el).addClass('is-invalid'); 
		},
		use_time() {
            var dt = moment(this.datetime, this.display_format).set({hour:0, minute:0});
            this.display_format = this.use_time ? date_picker_datetime_format : date_picker_date_format;
            this.config.format = this.display_format;
            this.datetime = dt.format(this.display_format);
		}
	}
});



//! Form submit/cancel button group.
//! Displays a set of Submit and Cancel button to save and/or cancel the form.
//! Save button has two props:
//! - submit ... the method to be called on click
//! - state  ... the validation object with the form state or boolean value that disables the save button on invalid state
Vue.component('asis-submit-control', {
    template: `
        <div>
            <asis-validation-alert :state="state"></asis-validation-alert>
            <asis-soft-error :error="error">{{error_message}}</asis-soft-error>
            <b-form-group class="form-group-submit">
                <b-button v-if="submitEnabled" variant="success" @click="submit()" :disabled="submit_disabled">
                    <i class="fas mr-2" :class="{'fa-circle-notch fa-spin':showSpinner,'fa-arrow-alt-circle-right':!showSpinner}"></i>
                    {{ submitLabel ? submitLabel : $t('submit')}}
                </b-button>
                <slot></slot>
                <b-button variant="primary" @click="$router.back()" :disabled="progress>0"><i class="fas fa-arrow-left mr-2"></i>{{ cancelLabel ? cancelLabel : $t('cancel')}}</b-button>

                <b-progress v-if="progress>0" :max="100" show-progress animated variant="info" class="w-lg-25 w-sm-50 mt-2">
                    <b-progress-bar :value="progress" :label="(progress<100) ? $t('uploading') : $t('processing')"></b-progress-bar>
                </b-progress>
            </b-form-group>
        </div>
        `,

    props: {
        submit: Function,           // submit function callback
        submitLabel: String,        // label for submit button (optional)
        submitEnabled: {
            type: Boolean,     // enable submit button
            default: true,
        },
        cancelLabel: String,        // label for submit button (optional)
        error: String,              // soft error code
        error_message: String,      // soft error message
        state: [Boolean, Object],   // validation state (optional)
        progress: Number,           // submit progress counter (optional)
        showSpinner: Boolean,       // show spinning indicator
    },

    i18n: {
        messages: {
            cz: {
                "submit": "Uložit",
                "cancel": "Zpět bez uložení",
                "uploading": "NAHRAVÁM",
                "processing": "ZPRACOVÁVÁM",
            },
            en: {
                "submit": "Save",
                "cancel": "Cancel w/o saving",
                "uploading": "UPLOADING",
                "processing": "PROCESSING",
            }
        }
    },

    computed: {
        submit_disabled() {
            var valid = true;
            if (typeof this.state === 'boolean') valid = this.state;
            if ((typeof this.state === 'object') && (this.state!==null)) valid = !this.state.$invalid;
            return (!valid) || (this.progress>0) || (this.showSpinner);
        }
    }
});



//! Form button group with a Submit button only.
//! Displays a Submit button to save the form.
//! Save button has two props:
//! - submit ... the method to be called on click
//! - state  ... the validation object with the form state that disables the save button on invalid state
//! Example: <asis-submit-button :state="$v" :result="save_result"></asis-submit-button>
Vue.component('asis-submit-button', {
    template: `
        <b-form-group class="form-group-submit">
            <b-button type="submit" variant="success" :disabled="state && state.$invalid">
                <!-- <i class="fas fa-arrow-alt-circle-right"></i> -->
                <i class="fas fa-check mr-2" :class="{'fa-circle-notch fa-spin':saving}"></i>{{$t('submit')}}
            </b-button>
            <!-- <b-spinner style="width: 1.3rem; height: 1.3rem;"type="grow" v-show="asep_import_runing" class="ml-2"></b-spinner> -->
            <!--
            <b-alert class="ml-3 mr-4 p-2 d-inline-block fade-in-long" style="border:none;background:none;" :show="result && result.ok" variant="success" fade><i class="fas fa-check"></i>&nbsp;{{$t('submitted')}}</b-alert>
            <b-alert class="ml-3 mr-4 p-2 d-inline-block fade-in-long" style="border:none;background:none;" :show="result && result.error" variant="danger" fade><i class="fas fa-times"></i>&nbsp;{{$t('error')}}</b-alert>
            -->
        </b-form-group>
        `,
    props: ['progress','state','saving'],

    i18n: {
        messages: {
            cz: {
                "submit": "Uložit",
                "submitted": "Uloženo",
                "error": "Chyba",
            },
            en: {
                "submit": "Save",
                "submitted": "Saved",
                "error": "Error",
            }
        }
    }
});



//! Form validation feedback component.
//! Displays an error message inside form-validation-feedback container for each
//! validation condition that is not fulfilled (evaluates to invalid).
Vue.component('asis-validation-feedback', {
    template: `
        <b-form-invalid-feedback :force-show="state.$invalid">
            <div v-for="error in errors">{{ error }}</div>
        </b-form-invalid-feedback>
    `,

    props: ['state', 'message'],

    i18n: {
        messages: {
            cz: {
                "required": "Toto pole je povinné.",
                "sameAs": "Obsah neodpovídá požadované hodnotě.",
                "notSameAs": "Obsah se musí lišit.",
                "format": "Zadaná hodnota neodpovídá požadovanému vzoru.",
                "personal_number": "Neplatné osobní číslo.",
                "year": "Neplatný rok.",
                "date": "Neplatné datum.",
                "date_after": "Datum musí následovat předchozí datum.",
                "date_future": "Datum musí být dnešní nebo pozdější.",
                "same_year": "Datumy musí být ve stejný rok.",
                "time": "Neplatný čas.",
                "integer": "Neplatné číslo.",
                "decimal": "Neplatné číslo.",
                "phonenumber": "Zadejte telefonní číslo ve tvaru 111222333 (bez mezer).",
                "token": "Zadaný údaj obsahuje nepovolené znaky (pouze a-z,A-Z,_).",
                "time_range": "Neplatný časový rozsah.",
                "min_length": "Minimální počet znaků je {0}.",
                "min_length_alt": "Text nemá minimální požadovaný počet znaků.",
                "max_length": "Maximální počet znaků je {0}.",
                "max_length_alt": "Text překračuje maximální povolený počet znaků.",
                "email": "Emailová adresa nemá správný tvar.",
                "full_name_with_comma": "Jméno musí být ve tvaru Příjmení, Jméno.",
                "file": "Soubor není vybrán.",
                "file_alt": "Soubor není vybrán.",
                "min_value": "Hodnota musí být alespoň {0}.",
                "min_value_alt": "Není dosažena minimální požadovaná hodnota.",
                "max_value": "Hodnota musí být nejvýše {0}.",
                "max_value_alt": "Překročena maximální hodnota.",
                "max_words": "Překročen maximální počet slov.",
                "max_words_alt": "Překročen maximální počet slov.",
                "orcidid": "Neplatný ORCID identifikátor.",
            },
            en: {
                "required": "Required field.",
                "sameAs": "Content does not match the required value.",
                "notSameAs": "Content must differ.",
                "format": "Value does not match the required pattern.",
                "personal_number": "Invalid personal number.",
                "year": "Invalid year.",
                "date": "Invalid date.",
                "date_after": "The date must follow the previous date.",
                "date_future": "The date has to be today of later.",
                "same_year": "The date have to be in the same year.",
                "time": "Invalid time.",
                "integer": "Invalid number.",
                "decimal": "Invalid number.",
                "phonenumber": "Enter phone number in plain numeric format 111222333 (w/o spaces).",
                "token": "Value contains illegal characters (a-z,A-Z,_ only).",
                "time_range": "Invalid time range.",
                "min_length": "Minimal length is {0} characters.",
                "min_length_alt": "Text does not meet the minimal required length.",
                "max_length": "Minimal length is {0} characters.",
                "max_length_alt": "Text exceeds the maximal allowed length.",
                "email": "A valid email address is required.",
                "full_name_with_comma": "A full name in format 'last name, first name' is required.",
                "file": "No file is selected.",
                "file_alt": "No file is selected.",
                "min_value": "Value must be at least {0}.",
                "min_value_alt": "Minimum allowed value not reached.",
                "max_value": "Value must be at most {0}.",
                "max_value_alt": "Maximum allowed value exceeded.",
                "max_words": "Maximum nuber of words exceeded.",
                "max_words_alt": "Maximum nuber of words exceeded.",
                "orcidid": "Invalid ORCID identifier.",
            }
        }
    },

    computed: {
        errors: function() {
            if (this.message) return [this.message];

            var errors = [];
            var $s = this.state;
            var $p = this.state.$params;
            var $t = this.$t.bind(this);

            if (false===$s.required) errors.push($t('required'));
            if (false===$s.sameAs) errors.push($t('sameAs'));
            if (false===$s.notSameAs) errors.push($t('notSameAs'));
            if (false===$s.format) errors.push($t('format'));
            if (false===$s.year) errors.push($t('year'));
            if (false===$s.date) errors.push($t('date'));
            if (false===$s.dateAfter) errors.push($t('date_after'));
            if (false===$s.dateInFuture) errors.push($t('date_future'));
            if (false===$s.sameYear) errors.push($t('same_year'));
            if (false===$s.time) errors.push($t('time'));
            if (false===$s.integer) errors.push($t('integer'));
            if (false===$s.decimal) errors.push($t('decimal'));
            if (false===$s.phonenumber) errors.push($t('phonenumber'));
            if (false===$s.token) errors.push($t('token'));
            if (false===$s.email) errors.push($t('email'));
            if (false===$s.minLength) errors.push($p.minLength ? $t('min_length',[$p.minLength.min]) : $t('min_length_alt'));
            if (false===$s.maxLength) errors.push($p.maxLength ? $t('max_length',[$p.maxLength.max]) : $t('max_length_alt'));
            if (false===$s.personalNumber) errors.push($t('personal_number'));
            if (false===$s.timeRange) errors.push($t('time_range'));
            if (false===$s.fullNameWithComma) errors.push($t('full_name_with_comma'));
            if (false===$s.file) errors.push($p.file ? $t('file',[$p.file]) : $t('file_alt'));
            if (false===$s.minValue) errors.push($p.minValue ? $t('min_value',[$p.minValue.min]) : $t('min_value_alt'));
            if (false===$s.maxValue) errors.push($p.maxValue ? $t('max_value',[$p.maxValue.max]) : $t('max_value_alt'));
            if (false===$s.maxWords) errors.push($p.maxWords ? $t('max_words',[$p.maxWords.max]) : $t('max_words_alt'));
            if (false===$s.orcidid) errors.push($t('orcidid'));

            return errors;
        }
    }
});



//! Form validation alert component.
//! Displays an alert box if the validation state of the form is invalid.
Vue.component('asis-validation-alert', {
    template: `
        <b-alert variant="warning" :show="show">
            {{$t('has_errors')}}
        </b-alert>
    `,

    props: {
        state: [Object, Boolean]
    },

    i18n: {
        messages: {
            cz: {
                "has_errors": "Formulář obsahuje chyby, které je třeba před odesláním odstranit.",
            },
            en: {
                "has_errors": "The form has errors and can not be submitted before the issues are resolved."
            }
        }
    },

    computed: {
        show() {
            return (
                ((typeof this.state === 'boolean') && (this.state==false)) ||
                (((typeof this.state === 'object') && (this.state!==null)) && (this.state.$invalid==true))
            );
        }
    }
});



//! Form validation alert component.
//! Displays an alert box if the validation state of the form is invalid.
Vue.component('asis-soft-error', {
    template: `
        <b-alert variant="danger" :show="Boolean(error)" class="h5">
            <i class="fas fa-exclamation-triangle fa-lg mb-4 mr-4 float-left"></i><slot>{{error}}</slot>
        </b-alert>
    `,

    props: ['error', 'message'],
});






Vue.component('asis-confirmation-modal', {
    template: `
        <b-modal
            :id="id"
            :title="title"
            :ok-title="okTitle"
            :ok-variant="okVariant"
            :cancel-variant="cancelVariant"
            :cancel-title="cancelTitle"
            :hide-header-close="hideHeaderClose"
            :hide-header="hideHeader"
            :centered="centered"
            @cancel="resolvePromise(false)"
            @ok="resolvePromise(true)"
        >
            <slot></slot>
        </b-modal>
    `,

    data() {
        return {
            resolvePromise: null,
            rejectPromise: null
        };
    },

    props: {
        id: String,
        title: String,
        okTitle: String,
        okVariant: String,
        cancelTitle: String,
        cancelVariant: String,
        centered: {type:Boolean, default:true},
        hideHeader: {type:Boolean, default:false},
        hideHeaderClose: {type:Boolean, default:false},
    },

  methods: {
    show(group) {
      return new Promise((resolve, reject) => {
        this.group = group;
        this.$bvModal.show(this.id);

        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    }
  }
});




//----------------------------------------------------------------------------------------------

//----------------------------------------------------------------------------------------------

import EasyMDE from 'easymde';
// import marked from 'marked';

Vue.component('asis-markdown-editor', {
    template: `
        <div>
            <textarea
                class=""
                :name="name"
                v-bind:value="value"
                v-on:input="$emit('input', $event.target.value)"
                @input="handleInput($event.target.value)"
            />
            <p class="text-muted my-2 p-0">
            {{$t('markdown_help_text')}} <a href="https://www.markdownguide.org/basic-syntax/" target="_blank" class="alert-link">{{$t('markdown_help_link')}}</a>
            </p>

            <b-modal
                ref="linkModal"
                title="Submit Your Name"
                @ok="linkModalOk"
            >
                <form ref="linkForm" z-submit.stop.prevent="handleSubmit">
                    <b-form-group
                        label="Text"
                        label-for="easymde-link-text-input"
                    >
                        <b-form-input
                            id="easymde-link-text-input"
                            v-model="linkText"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group
                        label="URL"
                        label-for="easymde-link-url-input"
                        :state="linkState"
                        invalid-feedback="URL is required"
                    >
                        <b-form-input
                            id="easymde-link-url-input"
                            v-model="linkUrl"
                            required
                        ></b-form-input>
                    </b-form-group>
                </form>
            </b-modal>
        </div>
    `,

    props: {
        value: String,
        name: String,
        previewClass: String,
        autoinit: {
            type: Boolean,
            default: true,
        },
        highlight: {
            type: Boolean,
            default: false,
        },
        sanitize: {
            type: Boolean,
            default: false,
        },
        config: {
            type: Object,
            default: () => {},
        },
        previewRender: {
            type: Function,
            default: null,
        },
        insertImage: {
            type: Function,
            default: null,
        },
        insertLink: {
            type: Function,
            default: null,
        },
    },

    i18n: {
        messages: {
            cz: {
                markdown_help_text: "Obsah se formátuje pomocí značek Markdown, viz",
                markdown_help_link: "Nápověda k Markdown",
            },
            en: {
                markdown_help_text: "Enter content in Markdown format, for help see",
                markdown_help_link: "Markdown syntax",
            }
        }
    },

    data() {
        return {
            isValueUpdateFromInner: false,
            linkUrl: '',
            linkText: '',
            linkState: null,
            linkOnReady: null,
        };
    },

    mounted() {
        if (this.autoinit) this.initialize();
    },

    deactivated() {
        const editor = this.easymde;
        if (!editor) return;
        const isFullScreen = editor.codemirror.getOption('fullScreen');
        if (isFullScreen) editor.toggleFullScreen();
    },

    methods: {
        linkModalShow(editor, successCallback) {
            this.linkUrl = '';
            this.linkText = '';
            this.linkState = null;
            this.linkOnReady = successCallback;
            this.$refs['linkModal'].show();
        },

        linkModalOk(bvModalEvt) {
            bvModalEvt.preventDefault();            // prevent modal from closing
            this.linkModalSubmit();                 // trigger submit handler
        },

        linkModalValidate() {
            const valid = this.$refs['linkForm'].checkValidity();
            this.linkState = valid;
            return valid;
        },

          linkModalSubmit(bvModalEvt) {
            // exit when the form isn't valid
            if (!this.linkModalValidate()) return false;
            // Push the name to submitted names
            if (this.linkOnReady) this.linkOnReady(this.linkText, this.linkUrl);
            // hide the modal manually
            this.$nextTick(() => {
                this.$refs['linkModal'].hide();
            })
        },



        initialize() {
            const configs = Object.assign({
                element: this.$el.firstElementChild,
                initialValue: this.value || '',
                previewRender: this.previewRender,
                spellChecker: false,
                nativeSpellcheck: false,
                //status:  false,
                sideBySideFullscreen: false,
                showIcons: ['strikethrough','heading-2','heading-3','heading-4','horizontal-rule','table'],
                hideIcons: ['heading'],
                indentWithTabs: false,
                promptURLs: true,
                tabSize: 4,
                renderingConfig: {},
                uploadImage:true,
                status: false, // statusbar
                toolbar:[{
                    name: 'bold',
                    action: EasyMDE.toggleBold,
                    className: 'fas fa-bold',
                    title: 'Bold',
                }, {
                    name: 'italic',
                    action: EasyMDE.toggleItalic,
                    className: 'fas fa-italic',
                    title: 'Italic',
                }, {
                    name: 'strikethrough',
                    action: EasyMDE.toggleStrikethrough,
                    className: 'fas fa-strikethrough',
                    title: 'Strikethrough',
                }, '|', {
                    name: 'heading-2',
                    action: EasyMDE.toggleHeading2,
                    className: 'fas fa-header fa-heading header-2',
                    title: 'Heading 2',
                }, {
                    name: 'heading-3',
                    action: EasyMDE.toggleHeading3,
                    className: 'fas fa-header fa-heading header-3',
                    title: 'Heading 3',
                // }, {
                //     name: 'heading-4',
                //     action: EasyMDE.toggleHeading4,
                //     className: 'fas fa-header fa-heading header-4',
                //     title: 'Heading 4',
                }, '|', {
                    name: 'unordered-list',
                    action: EasyMDE.toggleUnorderedList,
                    className: 'fas fa-list-ul',
                    title: 'Generic List',
                }, {
                    name: 'ordered-list',
                    action: EasyMDE.toggleOrderedList,
                    className: 'fas fa-list-ol',
                    title: 'Numbered List',
                }, '|', {
                    name: 'link',
                    action: (editor) => {
                        this.linkModalShow(editor, (linkText,linkUrl)=>{
                            var cm = editor.codemirror;
                            var stat = this.easymde.getState(cm);
                            if (linkText.empty()) linkText = linkUrl;
                            this.editorReplaceSelection(cm, stat.link, ['['+linkText,'](#url#)'], linkUrl);
                        });
                    },
                    className: 'fas fa-link',
                    title: 'Create Link',
                // }, {
                //     name: 'table',
                //     action: EasyMDE.drawTable,
                //     className: 'fas fa-table',
                //     title: 'Insert Table',
                }, {
                    name: 'horizontal-rule',
                    action: EasyMDE.drawHorizontalRule,
                    className: 'fas fa-minus',
                    title: 'Insert Horizontal Line',
                }, '|', {
                    name: 'undo',
                    action: EasyMDE.undo,
                    className: 'fas fa-undo',
                    noDisable: true,
                    title: 'Undo',
                }, {
                    name: 'redo',
                    action: EasyMDE.redo,
                    className: 'fas fa-repeat fa-redo',
                    noDisable: true,
                    title: 'Redo',
                }, '|', {
                    name: 'preview',
                    action: EasyMDE.togglePreview,
                    className: 'fas fa-eye',
                    noDisable: true,
                    title: 'Toggle Preview',
                }, {
                    name: 'side-by-side',
                    action: EasyMDE.toggleSideBySide,
                    className: 'fas fa-columns',
                    noDisable: true,
                    noMobile: true,
                    title: 'Toggle Side by Side',
                }, {
                    name: 'fullscreen',
                    action: EasyMDE.toggleFullScreen,
                    className: 'fas fa-arrows-alt',
                    noDisable: true,
                    noMobile: true,
                    title: 'Toggle Fullscreen',
                }]
            }, this.configs);

            this.$emit('input', configs.initialValue);

            if (this.highlight) {
                configs.renderingConfig.codeSyntaxHighlighting = true;
            }

            // //marked.setOptions({ sanitize: this.sanitize });

            this.easymde = new EasyMDE(configs);

            //console.log(this.easymde);

            this.bindingEvents();

            //console.log('this.easymde.drawLink orig:', this.easymde.drawLink);
            this.easymde.drawLink = function(editor) {
                //alert('draw link func');
                //console.log(editor);
                // var cm = editor.codemirror;
                // var stat = getState(cm);
                // var options = editor.options;
                // var url = "http://";
                // if(options.promptURLs) {
                // 	url = prompt(options.promptTexts.link);
                // 	if(!url) {
                // 		return false;
                // 	}
                // }
                // _replaceSelection(cm, stat.link, options.insertTexts.link, url);
            };

            const className = this.previewClass || '';
            this.addPreviewClass(className);

            this.$nextTick(() => {
                this.$emit('initialized', this.easymde);
            });
        },

        bindingEvents() {
            this.easymde.codemirror.on('change', (instance, changeObj) => {
                if (changeObj.origin === 'setValue') {
                    return;
                }
                const val = this.easymde.value();
                this.handleInput(val);
            });

            this.easymde.codemirror.on('blur', () => {
                const val = this.easymde.value();
                this.handleBlur(val);
            });
        },

        addPreviewClass(className) {
            const wrapper = this.easymde.codemirror.getWrapperElement();
            const preview = document.createElement('div');
            wrapper.nextSibling.className += ` ${className}`;
            preview.className = `editor-preview ${className}`;
            wrapper.appendChild(preview);
        },

        handleInput(val) {
            this.isValueUpdateFromInner = true;
            this.$emit('input', val);
        },

        handleBlur(val) {
            this.isValueUpdateFromInner = true;
            this.$emit('blur', val);
        },

        editorReplaceSelection(cm, active, startEnd, url) {
            if (/editor-preview-active/.test(cm.getWrapperElement().lastChild.className))
                return;

            var text;
            var start = startEnd[0];
            var end = startEnd[1];
            var startPoint = {},
                endPoint = {};
            Object.assign(startPoint, cm.getCursor('start'));
            Object.assign(endPoint, cm.getCursor('end'));
            if (url) {
                start = start.replace('#url#', url);  // url is in start for upload-image
                end = end.replace('#url#', url);
            }
            if (active) {
                text = cm.getLine(startPoint.line);
                start = text.slice(0, startPoint.ch);
                end = text.slice(startPoint.ch);
                cm.replaceRange(start + end, {
                    line: startPoint.line,
                    ch: 0,
                });
            } else {
                text = cm.getSelection();
                cm.replaceSelection(start + text + end);

                startPoint.ch += start.length;
                if (startPoint !== endPoint) {
                    endPoint.ch += start.length;
                }
            }
            cm.setSelection(startPoint, endPoint);
            cm.focus();
        }
    },

    destroyed() {
        this.easymde = null;
    },

    watch: {
        value(val) {
            if (this.isValueUpdateFromInner) {
                this.isValueUpdateFromInner = false;
            } else {
                this.easymde.value(val);
            }
        },
    },
});

/* <style>
.vue-simplemde .markdown-body {
  padding: 0.5em
}

.vue-simplemde .editor-preview-active, .vue-simplemde .editor-preview-active-side {
  display: block;
}
</style> */
