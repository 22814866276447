import * as translators from '../../../../client/globals/translators.js';


const workflow_approval_details = {

    props: {
        roles: Array,
        approvals: [Array,Object],
        comments_internal: Boolean,
    },

    i18n: {
        messages: {
            cz: {
                originator: "Příkazce",
                administrator: "Správce",
                supervisor: "Vedoucí",
        
            }, 
            en: {
                originator: "Originator",
                administrator: "Administrator",
                supervisor: "Supervisor",
            }
        }
    },

    template: `
        <b-table-simple class="border-bottom">
        <b-tr v-for="role in roles" :key="role">
            <b-td>{{$t(role)}}</b-td>
            <b-td class="min">
                <i v-if="approvals[role]" class="fas" :class="approval_icon_class(approvals[role].response)"></i>
                <span v-else>&ndash;</span>
            </b-td>
            <b-td class="min">
                <span v-if="approvals[role]">{{datetime_timestamp2dmyyyy_hhmm(approvals[role].timestamp)}}</span>
            </b-td>
            <b-td>
                <span v-if="approvals[role]">{{approvals[role].comment}}</span>
            </b-td>
            <b-td>
                <span v-if="approvals[role]">{{approvals[role].comment_internal}}</span>
            </b-td>
        </b-tr>
        </b-table-simple>
    `,

    methods: {
        datetime_timestamp2dmyyyy_hhmm: translators.datetime_timestamp2dmyyyy_hhmm,

        approval_icon_class(status) {
            switch (status) {
                case 'Q': return 'text-warning fa-times-circle';
                case 'A': return 'text-success fa-check-circle';
                case 'R': return 'text-danger fa-times-circle';
                case 'C': return 'text-dark fa-times-circle';
            }
        }
    },

}



export default workflow_approval_details;
