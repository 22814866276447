import * as translators from '../../../../client/globals/translators.js';
import moment from 'moment';


const workflow_approval_status = {

    props: {
        status: String,
    },

    i18n: {
        messages: {
            cz: {
                status: {
                    "Q": "probíhá schvalování",
                    "A": "schváleno",
                    "R": "zamítnuto",
                    "C": "zrušeno"
                },
            }, 
            en: {
                status: {
                    "Q": "approving",
                    "A": "approved",
                    "R": "rejected",
                    "C": "canceled"
                },
            }
        }
    },

    template: `
        <b-badge :variant="status_class" class="ml-2 px-2 font-size-lg text-uppercase">{{$t('status.'+status)}}</b-badge>
    `,

    computed: {
        status_class() {
            switch (this.status) {
                case 'Q': return 'warning';
                case 'A': return 'success';
                case 'R': return 'danger';
                case 'C': return 'dark';
            }
        }
    },

}



export default workflow_approval_status;
