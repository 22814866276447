const workflow_applicant = {

    props: {
        applicant: Object,
    },

    i18n: {
        messages: {
            cz: {
                name: "Jméno",
                department: "Oddělení",
                worksite: "Pracoviště",
            }, 
            en: {
                name: "name",
                department: "Department",
                worksite: "Worksite",
            }
        }
    },

    template: `
        <b-table-simple small class="border-bottom">
            <b-tr>
                <b-td>{{$t('name')}}</b-td>
                <b-td>{{applicant.last_name}}, {{applicant.first_name}} ({{applicant.pid}})</b-td>
            </b-tr>
            <b-tr>
                <b-td>{{$t('department')}}</b-td>
                <b-td>{{applicant.department}}</b-td>
            </b-tr>
            <b-tr>
                <b-td>{{$t('worksite')}}</b-td>
                <b-td>{{applicant.worksite}}</b-td>
            </b-tr>
        </b-table-simple>
    `,

}


export default workflow_applicant;
