import html from './workflow-usr-view.html';
import lang from './workflow-usr-view.lang.json';
import * as mixins from '../../../../client/globals/mixins.js';
import workflow_approval_status from '../components/workflow-approval-status.js';
import workflow_approval_details from '../components/workflow-approval-details.js';
import workflow_wfid_annualleave from './annualleave/workflow-wfid-annualleave.js';
import workflow_wfid_sickday from './sickday/workflow-wfid-sickday.js';
import server from '../../../../client/asis-server.js';



export default {
    template: html,

    components: {
        'annualleave': workflow_wfid_annualleave.form,
        'sickday': workflow_wfid_sickday.form,
        'workflow-approval-status': workflow_approval_status,
        'workflow-approval-details': workflow_approval_details,
    },

    i18n: {
        // extend local lang object with langs of workflows
        messages: {
            cz: Object.assign(lang.cz, {
                annualleave: workflow_wfid_annualleave.lang.cz,
                sickday: workflow_wfid_sickday.lang.cz,
            }),
            en: Object.assign(lang.en, {
                annualleave: workflow_wfid_annualleave.lang.en,
                sickday: workflow_wfid_sickday.lang.en,
            }),
        }
    },

    mixins: [
        mixins.module_name, mixins.loadDataOnCreate, 
    ],

    data: function() {
        return {
            wfid: this.$route.params.wfid,
            requisition: null,
            roles: [],
            can_cancel: false,
        };
    },


    methods: {
        load() {
            server.request(
                this.module_name, this.wfid+'-view', 
                this.$route.params, null,   // this.$route.params provides `requsition_id` and `secret`
                (data, extra) => {
                    this.requisition = data;
                    //this.months = Array.from({length:this.date_until.month()-this.date_from.month()+1}, (value,index) => this.date_from.month()+index);
                    this.roles = extra.roles;
                    this.can_cancel = extra.can_cancel;
                }
            );
        },

        cancel() {
            if (!this.can_cancel) return;
            if (!confirm(this.$t('cancel_confirmation'))) return;
            server.request(
                this.module_name, this.wfid+'-cancel', 
                this.$route.params, null,   // this.$route.params provides `requsition_id` and `secret`
                (success) => {
                    // if ok, go to previous view
                    this.$bvModal.msgBoxOk(this.$t('cancel_ok'),{centered:true}).then(value => {
                        this.back();
                    });
                }, 
                (error) => {
                    this.$bvModal.msgBoxOk(this.$t(this.wfid+'.'+error),{centered:true});
                }
            );
        },

        back() {
            if (window.history.length>1) 
                this.$router.back(); 
            else 
                this.$router.replace({path:'/timesheets-usr'});
        },

    },


};
