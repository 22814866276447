import html from './workflow-mgr-approve.html';
import lang from './workflow-mgr-approve.lang.json';
// import * as directives from '../../../../client/globals/directives.js';
// import * as validators from '../../../../client/globals/validators.js';
// import * as translators from '../../../../client/globals/translators.js';
import * as mixins from '../../../../client/globals/mixins.js';
import workflow_applicant from '../components/workflow-applicant.js';
import workflow_approval_alerts from '../components/workflow-approval-alerts.js';
import workflow_approval_controls from '../components/workflow-approval-controls.js';
import workflow_approval_status from '../components/workflow-approval-status.js';
import workflow_approval_details from '../components/workflow-approval-details.js';
import workflow_wfid_annualleave from './annualleave/workflow-wfid-annualleave.js';
import workflow_wfid_sickday from './sickday/workflow-wfid-sickday.js';
import server from '../../../../client/asis-server.js';



export default {
    template: html,

    mixins: [
        mixins.module_name, 
        mixins.loadDataOnCreate, 
    ],

    components: {
        'annualleave': workflow_wfid_annualleave.view,
        'sickday': workflow_wfid_sickday.view,
        'workflow-applicant': workflow_applicant,
        'workflow-approval-alerts': workflow_approval_alerts,
        'workflow-approval-controls': workflow_approval_controls,
        'workflow-approval-status': workflow_approval_status,
        'workflow-approval-details': workflow_approval_details,
    },

    i18n: {
        // extend local lang object with langs of workflows
        messages: {
            cz: Object.assign(lang.cz, {
                annualleave: workflow_wfid_annualleave.lang.cz,
                sickday: workflow_wfid_sickday.lang.cz,
            }),
            en: Object.assign(lang.en, {
                annualleave: workflow_wfid_annualleave.lang.en,
                sickday: workflow_wfid_sickday.lang.en,
            }),
        }
    },

    data: function() {
        return {
            wfid: this.$route.params.wfid,
            requisition: null,
            responded: false,
            roles: [],
        };
    },

    methods: {
        load() {
            server.request(
                this.module_name, this.wfid+'-view', 
                this.$route.params, null,   // this.$route.params provides `requsition_id`, `secret` and `role`
                (data, extra) => {
                    this.requisition = data;
                    this.requisition.applicant = extra.applicant;
                    this.roles = extra.roles;
                    this.responded = extra.responded;
                }
            );
        },

        save(approve, comment, comment_internal) {
            if (!approve && !comment) {
                throw 'no reason for rejection';
                return;
            }

            server.request(
                this.module_name, this.wfid+'-approve', 
                Object.assign({}, this.$route.params, {approve, comment, comment_internal}), null,
                (success) => {
                    this.$bvModal.msgBoxOk(this.$t('request_sent'),{centered:true}).then(value => {
                        this.$router.push('/timesheets-mgr');
                    });
                },
            );
        },

        // getclass_calendar(ymd, date) {
        //     var year  = date.getFullYear();
        //     var month = date.getMonth()+1;
        //     var day   = date.getDate();
        //     if (this.year==year && this.month==month && this.days_requested.includes(day)) return 'ho-request';
        //     if (this.year==year && this.month==month && this.days_approved.includes(day)) return 'ho-approved';
        //     return null;
        // },
    },

};
