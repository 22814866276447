import html from './timesheet-container.html';
import lang from './timesheet-container.lang.json';
import * as mixins from '../../../client/globals/mixins.js';
import server from '../../../client/asis-server.js';
import timesheet_tab from './timesheet-tab.js';
import projectsheet_tab from './components/projectsheet-tab.js';


export default {
    props: {
        pid: Number,
        date: Object,
        impersonificationMode: Boolean,
    },

    template: html,

    i18n: {
        messages: lang
    },

    mixins: [
        mixins.loadDataOnCreate, 
        mixins.module_name,
    ],

    components: {
        'timesheet-tab': timesheet_tab,
    },

    data: function() {
        return {
            contracts: [],
            timesheet_valid: false,
            timesheet_locked: true,
            timesheet_print_url: null,
            timesheet_busy: false,
        };
    },

    methods: {
        load() {
            this.timesheet_busy = true;
            server.request(
                this.module_name, 'contracts-get', {pid:this.pid, year:this.date.year(), month:this.date.month()+1}, null,
                (data)=>{
                    this.contracts = data;
                }
            ).then(() => {
                this.timesheet_busy = false;
            });
        },

        // timesheettab_onvalidation(status) {
        //     console.log('timesheettab_onvalidation', status);
        //     this.$emit("validation", status);
        // },

        // timesheettab_onchange(status) {
        //     console.log('timesheettab_onchange', status);
        //     this.$emit("change", status);
        // },

        // timesheet_save() {
        //     this.$refs['timesheet-container'].save(false);
        // },

        // timesheet_check() {
        //     this.$refs['timesheet-container'].save(true);
        // },

        // timesheet_onvalidation(status) {
        //     console.log('timesheet_onvalidation', status);
        //     this.timesheet_valid = status;
        // },

        // timesheet_onchange(status) {
        //     if (typeof status.timesheet_busy !== 'undefined') this.timesheet_busy = status.timesheet_busy;
        //     if (typeof status.can_edit !== 'undefined') this.timesheet_locked = !status.can_edit;
        //     if (typeof status.print_url !== 'undefined') this.timesheet_print_url = status.print_url;
        // },

    },


    watch: {
        // reload data whenever date or pid change
        'pid': function() {this.load();},
        'date': function() {this.load();},  
    }
};

 
