import html from './workflow-wfid-annualleave.html';
import lang from './workflow-wfid-annualleave.lang.json';
import * as directives from '../../../../../client/globals/directives.js';
import * as validators from '../../../../../client/globals/validators.js';
import * as translators from '../../../../../client/globals/translators.js';
import moment from 'moment';

const annualleave_request_form = {

    props: {
        value: Object,
        date: String,
        readonly: Boolean,
    },

    data() {
        return { 
            // make a local copy of `value` prop
            requisition: Object.assign({
                date_from: this.value.requisition_id ? this.value.details.date_from : this.date,
                date_until: this.value.requisition_id ? this.value.details.date_until : this.date,
                half_day: this.value.requisition_id ? this.value.details.half_day : false,
                half_part: this.value.requisition_id ? this.value.details.half_part : null,
                off_for_wf: this.value.requisition_id ? this.value.details.off_for_wf : false,
                comment: this.value.requisition_id ? this.value.comment : '',
            }, this.value),
        };
    },


    mixins: [
        directives.label_required, validators.vuelidate,
    ],

    i18n: {
        messages: lang
    },

    template: html,

    validations() {
        return {
            requisition: {
                date_from: {
                    required: validators.required,
                    date: validators.asis_date,
                    dateInFuture: validators.dateSameOrFuture(),
                },
                date_until: {
                    required: validators.required,
                    date: validators.asis_date,
                    dateInFuture: validators.dateSameOrFuture(),
                    dateAfter: validators.dateSameOrAfter('date_from'),
                    sameYear: validators.sameYear('date_from'),
                },
                half_day: {
                },
                half_part: {
                },
                off_for_wf: {
                    required: validators.required,
                },
                comment: {
                    maxLength: validators.maxLength(64000),
                },
            }
        };
    },

    mounted: function () {
        if (this.readonly) return;

        // update parent once the component is mounted
        this.$emit('input', this.requisition);
        this.$emit('v', this.$v);
    },

    watch: {
        // deep-watch request object and send updates to parent component
        requisition: {
            deep: true,
            handler(value) {
                if (this.readonly) return false;
                if (value.date_from!=value.date_until) value.half_day=false;
                this.$emit('input', value);
                this.$emit('v', this.$v);
            }
        }
    },

    computed: {
        days() {
            var d1 = moment(this.requisition.date_from, 'YYYY/MM/DD');
            var d2 = moment(this.requisition.date_until, 'YYYY/MM/DD');
            return this.requisition.half_day ? '1/2' : d2.diff(d1,'days') + 1;
        }
    }
}



const annualleave_request_lang = {
    cz: {
        "form_heading": "Žádost o dovolenou za kalendářní rok",
        "status_engaged": "Žádost nelze zpracovat. V zadaném období jsou zadány jiné nepřítomnosti.",
    },
    en: {
        "form_heading": "Request for annual leave",
        "status_engaged": "Request cannot be processed. There are already other absences in the selected period.",
    },
};


export default {
    form: annualleave_request_form,
    lang: annualleave_request_lang,
};
