import html from './workflow-usr-request.html';
import lang from './workflow-usr-request.lang.json';
import * as directives from '../../../../client/globals/directives.js';
import * as validators from '../../../../client/globals/validators.js';
import * as translators from '../../../../client/globals/translators.js';
import * as mixins from '../../../../client/globals/mixins.js';
import workflow_wfid_annualleave from './annualleave/workflow-wfid-annualleave.js';
import workflow_wfid_sickday from './sickday/workflow-wfid-sickday.js';
import server from '../../../../client/asis-server.js';
import moment from 'moment';



export default {
    template: html,

    components: {
        'annualleave': workflow_wfid_annualleave.form,
        'sickday': workflow_wfid_sickday.form,
    },

    i18n: {
        // extend local lang object with langs of workflows
        messages: {
            cz: Object.assign(lang.cz, {
                annualleave: workflow_wfid_annualleave.lang.cz,
                sickday: workflow_wfid_sickday.lang.cz,
            }),
            en: Object.assign(lang.en, {
                annualleave: workflow_wfid_annualleave.lang.en,
                sickday: workflow_wfid_sickday.lang.en,
            }),
        }
    },

    mixins: [
        mixins.module_name, mixins.loadDataOnCreate, 
        //mixins.storeGetters(['user'],'asis/auth'),              // provides this.user in the component
        //directives.label_required, validators.vuelidate,
    ],

    data: function() {
        return {
            wfid: this.$route.params.wfid,
            date: moment(this.$route.params.date, 'YYYYMMDD').format(translators.asis_date_format),
            request: {},
            request_$v: null,
            submit_error: null,
        };
    },

    methods: {
        submit() {
            server.request(
                this.module_name, this.wfid+'-request', 
                this.request, null,
                (success) => {
                    // if ok, go to previous view
                    this.$bvModal.msgBoxOk(this.$t('request_sent'),{centered:true}).then(value => {
                        this.$router.back();
                    });
                }, 
                (error) => {
                    this.submit_error = error;
                }
            ).then(()=>{
            });
        },

    }

};
