import * as translators from '../../../../client/globals/translators.js';
import moment from 'moment';


const workflow_approval_controls = {

    props: {
    },

    data: function() {
        return {
            comment: '',
            comment_internal: '',
        };
    },

    i18n: {
        messages: {
            cz: {
                comment_hint: "(poznámka pro žadatele)",
                comment_internal_hint: "(interní poznámka pro ostatní schvalovatele)",
                button_approve: "Schválit",
                button_reject: "Neschválit",
                error_no_reject_message: "Při zamítnutí žádosti je nutné uvést důvod.",
            }, 
            en: {
                comment_hint: "(comment for applicant)",
                comment_internal_hint: "(internal comment for other approvers)",
                button_approve: "Accept",
                button_reject: "Reject",
                error_no_reject_message: "You must give the reason for rejection.",
            }
        }
    },

    template: `
        <div>
            <b-form-textarea
                class="w-md-100 w-lg-75 w-xl-50 mt-4"
                v-model="comment"
                rows="3"
                max-rows="10"
            ></b-form-textarea>
            <small class="form-text text-muted">{{$t('comment_hint')}}</small>

            <b-form-textarea
                class="w-md-100 w-lg-75 w-xl-50 mt-4"
                v-model="comment_internal"
                rows="3"
                max-rows="10"
            ></b-form-textarea>
            <small class="form-text text-muted">{{$t('comment_internal_hint')}}</small>

            <b-button variant="success" @click="approve" class="main-button my-3 mr-4">{{$t('button_approve')}}</b-button>
            <b-button variant="danger"  @click="reject"  class="main-button my-3 mr-4">{{$t('button_reject')}}</b-button>
        </div>
    `,

    methods: {
        approve() {
            this.$emit('accept', {comment:this.comment, comment_internal:this.comment_internal});
        },

        reject() {
            // check if comment is given
            if (!this.comment) {
                this.$bvModal.msgBoxOk(this.$t('error_no_reject_message'), {centered:true}).catch(e=>{});
                return;
            }

            this.$emit('reject', {comment:this.comment, comment_internal:this.comment_internal});
        },
    },

}



export default workflow_approval_controls;
